<template>
  <div>
    <h1>สรุปภาพรวมตัวแทน</h1>
    <h1>Powerball</h1>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerball.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerball.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerball.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>หวยลาว</h1>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserLao.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDateLao.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDateLao.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <h2>เลขท้ายใน Stock</h2> -->
    <!-- <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card class="pa-2">
          <h3 style="padding: 10px"><b>เลขท้าย 2 ตัว</b></h3>
          <v-data-table
            :headers="headerstwoDigits"
            :items="twoDigits"
            :sort-by="['calories', 'fat']"
            :sort-desc="[false, true]"
            :items-per-page="15"
            multi-sort
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="pa-2">
          <h3 style="padding: 10px"><b>เลขท้าย 3 ตัว</b></h3>
          <v-data-table
            :headers="headersthreeDigits"
            :items="threeDigits"
            :sort-by="['calories', 'fat']"
            :sort-desc="[false, true]"
            :items-per-page="15"
            multi-sort
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()">สร้าง Admin</v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersDashboard"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewUser(item)">mdi-pencil</v-icon>
                <v-icon @click="DeleteUser(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      search: "",
      count: 0,
      headersDashboard: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "firstName", align: "center" },
        { text: "นามสกุล", value: "lastName", align: "center" },
        { text: "อีเมล", value: "email", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "telNumber", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      headerstwoDigits: [
        {
          text: "เลขท้าย 2 ตัว",
          align: "start",
          sortable: false,
          value: "last2Number",
        },

        { text: "จำนวนคงเหลือ", value: "countAmt" },
      ],
      twoDigits: [],
      headersthreeDigits: [
        {
          text: "เลขท้าย 3 ตัว",
          align: "start",
          sortable: false,
          value: "last3Number",
        },

        { text: "จำนวนคงเหลือ", value: "countAmt" },
      ],
      threeDigits: [],
      userPuchaseAmt: 0,
      countPeriodSell: 0,
      countthisPeriodSell: 0,
      dashboardData: null,
    };
  },
  created() {
    this.getDashboard();
    this.gettwoDigits();
    this.getthreeDigits();
    this.getUserPurchase();
    this.gethisPeriodSell();
    this.callCountPeriodSell();
    this.getData();
  },
  methods: {
    async getData() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users?userType=USER`,
        auth
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    async getDashboard() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/dashboard`,
        auth
      );
      console.log("dashboardData", response.data.data);
      this.dashboardData = response.data.data;
    },
    async gettwoDigits() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getLast2Amt`,
        auth
      );
      console.log("getAllLotto", response.data.data);
      this.twoDigits = response.data.data;
      for (let i in this.twoDigits) {
        this.twoDigits[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async getthreeDigits() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getLast3Amt`,
        auth
      );
      console.log("getAllLotto", response.data.data);
      this.threeDigits = response.data.data;
      for (let i in this.threeDigits) {
        this.threeDigits[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async getUserPurchase() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/userPuchaseAmt`,
        auth
      );
      console.log("userPuchaseAmt", response.data.data[0]);
      this.userPuchaseAmt = response.data.data[0].countUser;
    },
    async gethisPeriodSell() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/countPeriodSell?lotteryDate='2021-08-01'`,
        auth
      );
      console.log("countThisPeriodSell", response.data.data[0]);
      this.countThisPeriodSell = response.data.data[0].countAmt;
    },
    async callCountPeriodSell() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/countPeriodSell`,
        auth
      );
      console.log("countPeriodSell", response.data.data[0]);
      this.countPeriodSell = response.data.data[0].countAmt;
    },
  },
};
</script>
